.gpt3__brand {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.gpt3__brand div {
  flex: 1;
  max-width: 150px;
  min-width: 120px;
  margin: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
}